<template>
  <div>

    <div class="card">
      <div class="card-body">

        <div class="position-relative wd-50 row">
          <div class="col-7">
            <div class="input-group">
              <input type="text" class="form-control ps-5" v-model="postcode" placeholder="Enter postcode..."> <span class="position-absolute top-50 product-show translate-middle-y" style="left: 25px;"><i class="bx bx-search"></i></span>
              <submit-button type="button" text="Search" :clicked="loading" @click="loadJobs" class="btn btn-primary px-5"></submit-button>
            </div>

          </div>
          <div class="col-4">
            <button type="button" @click="$refs.newjobform.show()" class="btn btn-primary px-5 ms-3">New Job</button>
          </div>
          <div class="col-1">
            <button type="button" class="btn btn-light ms-2" style="float: right;" title="Manage Columns" data-bs-toggle="modal" data-bs-target="#manageColumnsModal">
              <i class="bx bxs-grid me-0"></i>
            </button>
          </div>
        </div>

        <br/>

        <table id="example" class="table table-hover" style="width:100%">
          <thead>
          <tr>
            <th></th>
            <th v-show="columns.id">#</th>
            <th>Distance</th>
            <th v-show="columns.created_at">Date Created</th>
            <th v-show="columns.type">Type</th>
            <th v-show="columns.description" style="min-width: 50ch; white-space: normal;">Description</th>
            <th v-show="columns.town" style="min-width: 20ch; white-space: normal;">Town</th>
            <th v-show="columns.postcode">Postcode</th>
            <th v-show="columns.status">Status</th>
            <th v-show="columns.invoiced">Invoiced?</th>
            <th v-show="columns.users">Assigned Users</th>
            <th v-show="columns.site" style="min-width: 20ch; white-space: normal;">Site</th>
            <th v-show="columns.client">Client</th>
            <th v-show="columns.completed_at">Completed At</th>
            <th v-show="columns.completed_by">Completed By</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="jobs.length < 1">
            <td colspan="12" class="text-center">No jobs could be found.</td>
          </tr>
          <tr v-for="job in jobs" :key="job.id" style="cursor: pointer;">
            <td>
              <button class="btn btn-sm btn-primary" @click="downloadJobSheet(job)" v-if="job.status == 1"><i class="bx bxs-printer me-1"></i></button>
            </td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.id">{{job.id}}</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.created_at">{{ parseFloat(job.distance).toFixed(2) }} Miles</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.created_at">{{ job.created_at }}</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.type">{{job.type}}</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.description" style="min-width: 50ch; white-space: normal;">{{job.description}}</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.town" style="min-width: 20ch; white-space: normal;">{{job.site_town}}</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.postcode">{{job.site_postcode}}</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.status">
              <div v-if="job.status == 1" class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-success"></i>COMPLETE</div>
              <div v-else-if="job.status == 2" class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-warning"></i>ASSIGNED</div>
              <div v-else-if="job.status == 5" class="badge rounded-pill bg-warning p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-primary">REQUIRES PREP</i></div>
              <div v-else class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-danger"></i>TBA</div>

              <div v-if="job.status == 1 && (job.not_completed_notes || '').length > 10" class="badge rounded-pill bg-light p-2 text-uppercase px-3 ms-2"><i class="bx bxs-circle me-1 text-warning"></i>FURTHER WORK</div>
            </td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.invoiced">
              <div v-if="job.invoiced == 1" class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-success"></i>INVOICED</div>
              <div v-else-if="job.invoiced == 2" class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-success"></i>MAINTENANCE</div>
              <div v-else class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-warning"></i>NOT INVOICED</div>
            </td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.users">
              <span v-if="job.users?.length < 1">None</span>
              <ul v-else v-for="user in job.users" :key="'jobuser-' + user.id" style="list-style-type:none;">
                <li>{{user.fname}} {{user.lname}}</li>
              </ul>
            </td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.site" style="min-width: 20ch; white-space: normal;">{{job.site_name}}</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.client">{{job.client_name}}</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.completed_at"><Time v-if="job.completed_at" :datetime="job.completed_at" format="dd/MM/yyyy"></Time></td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.completed_by"><span v-if="job.completed_user">{{job.completed_user.fname}} {{job.completed_user.lname}}</span></td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th></th>
            <th v-show="columns.id">#</th>
            <th>Distance</th>
            <th v-show="columns.created_at">Date Created</th>
            <th v-show="columns.type">Type</th>
            <th v-show="columns.description" style="min-width: 50ch; white-space: normal;">Description</th>
            <th v-show="columns.town" style="min-width: 20ch; white-space: normal;">Town</th>
            <th v-show="columns.postcode">Postcode</th>
            <th v-show="columns.status">Status</th>
            <th v-show="columns.invoiced">Invoiced?</th>
            <th v-show="columns.users">Assigned Users</th>
            <th v-show="columns.site" style="min-width: 20ch; white-space: normal;">Site</th>
            <th v-show="columns.client">Client</th>
            <th v-show="columns.completed_at">Completed At</th>
            <th v-show="columns.completed_by">Completed By</th>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <!-- MODALS -->
    <new-job-form id="jobsNewJobForm" v-if="!$hasRole('Customer')" @reload="loadJobs(pagination.page)" :site_id="lockSiteID" :client_id="lockClientID" ref="newjobform"></new-job-form>

    <div class="modal fade" id="manageColumnsModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Manage Columns</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Please note these settings will be saved locally in your browser. If you clear your browser settings these settings may reset.</p>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="idColumn" @change="updateColumnSettings" v-model="columns.id">
              <label class="form-check-label" for="idColumn">ID</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="created_atColumn" @change="updateColumnSettings" v-model="columns.created_at">
              <label class="form-check-label" for="created_atColumn">Created At</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="typeColumn" @change="updateColumnSettings" v-model="columns.type">
              <label class="form-check-label" for="typeColumn">Type</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="descriptionColumn" @change="updateColumnSettings" v-model="columns.description">
              <label class="form-check-label" for="descriptionColumn">Description</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="townColumn" @change="updateColumnSettings" v-model="columns.town">
              <label class="form-check-label" for="townColumn">Town</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="postcodeColumn" @change="updateColumnSettings" v-model="columns.postcode">
              <label class="form-check-label" for="postcodeColumn">Postcode</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="statusColumn" @change="updateColumnSettings" v-model="columns.status">
              <label class="form-check-label" for="statusColumn">Status</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="invoicedColumn" @change="updateColumnSettings" v-model="columns.invoiced">
              <label class="form-check-label" for="invoicedColumn">Invoiced</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="usersColumn" @change="updateColumnSettings" v-model="columns.users">
              <label class="form-check-label" for="usersColumn">Users</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="siteColumn" @change="updateColumnSettings" v-model="columns.site">
              <label class="form-check-label" for="siteColumn">Site</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="clientColumn" @change="updateColumnSettings" v-model="columns.client">
              <label class="form-check-label" for="clientColumn">Client</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="completed_atColumn" @change="updateColumnSettings" v-model="columns.completed_at">
              <label class="form-check-label" for="completed_atColumn">Completed At</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="completed_byColumn" @change="updateColumnSettings" v-model="columns.completed_by">
              <label class="form-check-label" for="completed_byColumn">Completed By</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeManageColumnsModal'>Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import SubmitButton from '../../../components/SubmitButton.vue';
// import Select2 from '../../../components/Select2.vue';
// import SecureImage from '../../../components/SecureImage.vue';
import Time from '../../../components/Time.vue';
import { saveAs } from 'file-saver';
import NewJobForm from "./NewJobForm";
import { DateTime } from 'luxon';
export default {
  components: {NewJobForm, SubmitButton, Time },
  name: 'admin.nearestjobs',
  props: {
    lockClientID: {
      type: Number,
      required: false
    },
    lockSiteID: {
      type: Number,
      required: false
    }
  },
  data(){
    return {
      jobs: [],
      pagination: {
        lastPage: 0,
        page: 0,
        showing: 0,
        total: 0
      },
      postcode: '',
      job: {
        site_id: null,
        description: null
      },
      creating: false,
      availableUsers: [],
      loading: false,
      columns: {
        id: true,
        created_at: true,
        type: true,
        description: true,
        town: true,
        postcode: true,
        status: true,
        invoiced: true,
        users: true,
        site: true,
        client: true,
        completed_at: true,
        completed_by: true
      }
    }
  },
  mounted(){
    if(this.$hasRole('Customer')){
      this.$router.push({name: 'customer.jobs'});
    }else{
      this.loadColumnSettings();
    }
  },
  methods: {
    loadJobs(){
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs/nearest?postcode=${this.postcode}`)
      .then(response => {
          this.jobs = response.data.jobs;
          this.jobs.forEach((job) => {
            job.created_at = DateTime.fromFormat(job.created_at, 'yyyy-MM-dd HH:mm:ss').toFormat('dd/MM/yyyy');
            switch(job.status){
              case null:
              case 0:
                job.realStatus = 'TBA';
                break;
              case 1:
                job.realStatus = 'Completed';
                break;
              case 2:
                job.realStatus = 'Assigned';
                break;
            }
          })
      })
      .catch(error => {
        this.$error('Failed to load jobs.', error);
      })
      .finally(() => {
        this.loading = false;
      });
    },
    updateColumnSettings(){
      localStorage.setItem('link::jobs::columns', JSON.stringify(this.columns));
    },
    loadColumnSettings(){
      let savedColumns = localStorage.getItem('link::jobs::columns');
      if(savedColumns !== undefined && savedColumns !== null && savedColumns !== 'null' && savedColumns !== ''){
        this.columns = JSON.parse(savedColumns);
      }
    },
    downloadJobSheet(job){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs/${job.id}/jobsheet`, {
        responseType: "blob"
      })
      .then(response => {
        saveAs(response.data, `Job Report #${job.id}.pdf`);
      })
      .catch(error => {
        this.$error("Failed to generate report", error);
      })
    },
  }
}
</script>

<style scoped>
  ul {
    margin-block-start: 0;
    padding-inline-start: 0;
  }

  td, th {
    width: 1%;
    white-space: nowrap;
  }
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
</style>

<style src="@vueform/multiselect/themes/default.css"></style>